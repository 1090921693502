const initLogoAnimation = () => {
	const containers = document.querySelectorAll('.logo-animated')

	if (containers.length) {
		containers.forEach(container => {
			const holder = container.querySelector('.logo-animated__middle')
			const texts = container.querySelectorAll('.logo-animated__middle__text')
			let textsCounter = 0
			let widths = []

			const setTextWidths = () => {
				widths = []

				texts.forEach(text => {
					widths.push(text.offsetWidth)
				})
			}

			window.onload = setTextWidths

			const fakeInterval = () => {
				setTextWidths()

				texts[textsCounter].classList.remove('active')

				holder.style.width = '0px'

				setTimeout(() => {
					texts[textsCounter].classList.add('active')

					holder.style.width = `${widths[textsCounter]}px`
				}, 1000)

				textsCounter < texts.length - 1 ? textsCounter++ : textsCounter = 0

				setTimeout(fakeInterval, 3000)
			}

			fakeInterval()
		})
	}
}

export default function logoAnimation() {
	initLogoAnimation()
}
