const WEATHER_API_KEY = process.env.WEATHER_API_KEY
const VARNA_LAT_LNG = 'lat=43.220712&lon=27.959106'

const sun = require('../images/weather/sun.webp')
const few_clouds = require('../images/weather/few_clouds.webp')
const clouds = require('../images/weather/clouds.webp')
const broken_clouds = require('../images/weather/broken_clouds.webp')
const rain = require('../images/weather/rain.webp')
const sun_rain = require('../images/weather/sun_rain.webp')
const thunder = require('../images/weather/thunder.webp')
const snow = require('../images/weather/snow.webp')
const fog = require('../images/weather/fog.webp')

const icons = {
	'01d': sun,
	'01n': sun,
	'02d': few_clouds,
	'02n': few_clouds,
	'03d': clouds,
	'03n': clouds,
	'04d': broken_clouds,
	'04n': broken_clouds,
	'09d': rain,
	'09n': rain,
	'10d': sun_rain,
	'10n': sun_rain,
	'11d': thunder,
	'11n': thunder,
	'13d': snow,
	'13n': snow,
	'50d': fog,
	'50n': fog
}

const initWeatherSimple = () => {
	const weatherWrapper = document.getElementById('weather-inline')

	if (weatherWrapper && WEATHER_API_KEY) {
		fetch(`https://api.openweathermap.org/data/2.5/weather?lat=43.220712&lon=27.959106&units=metric&${VARNA_LAT_LNG}&appid=${WEATHER_API_KEY}`).then(res => res.json()).then((data) => {
			if (typeof data === 'object' && 'weather' in data) {
				const iconCode = data.weather[0].icon
	
				const icon = document.createElement('img')
				icon.setAttribute('class', 'weather-inline__icon')
				icon.setAttribute('src', `${icons[iconCode]}`)
				icon.setAttribute('alt', 'weather-image')
	
				weatherWrapper.innerText = Math.round(data.main.temp) + '°'
				weatherWrapper.prepend(icon)
			}
		})
	}
}

export default function weatherUI() {
	initWeatherSimple()
}
