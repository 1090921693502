const initPreloader = () => {
	const preloader = document.querySelector('.preloader')

	if (preloader) {
		const handleLoader = () => {
			preloader.classList.add('hiding')

			setTimeout(() => {
				preloader.classList.add('hidden')
				document.body.removeAttribute('style')
			}, 500)
		}

		document.addEventListener('DOMContentLoaded', () => {
			// Add timeout just to be sure, that the loading indicator will not blink.
			setTimeout(() => {
				handleLoader()
			}, 1000)
		})
	}
}

export default function preloader() {
	initPreloader()
}
