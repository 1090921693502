const html = document.querySelector('html')
const body = document.querySelector('body')

export const navTrigger = document.querySelector('.nav-trigger')

const toggleNavTrigger = () => {
	navTrigger.addEventListener('click', function(e) {
		e.preventDefault()
		
		body.classList.add('p-fixed')
		body.classList.add('w-100')
		
		if (html.classList.contains('menu-is-opened')) {
			html.classList.add('menu-is-closing')
			
			setTimeout(() => {
				html.classList.remove('menu-is-closing')
				body.classList.remove('p-fixed')
				body.classList.remove('w-100')

			}, 1000)
		}

		html.classList.toggle('menu-is-opened')
	})
}

export default function headersUI() {
	toggleNavTrigger()
}
