let lastScroll = 0
const scrollDelta = 20
	
const observeScrollDirection = (currentScroll) => {
	const header = document.querySelector('.header')
	const headerHeight = header.offsetHeight

  
	// Make sure they scroll more than delta
	if(Math.abs(lastScroll - currentScroll) <= scrollDelta) return
	
	// And make sure scroll is more than header's height
	if (currentScroll <= headerHeight) {
		header.classList.remove('scrolling-down', 'scrolled')
		return
	}
	
	// Add scrolled class if user scroll more then header's height
	header.classList.toggle('scrolled', currentScroll >= headerHeight)
	
	// Add scrolling-down class if scroll directions is downwards AND user
	// is scrolled more then header's height
	header.classList.toggle('scrolling-down', currentScroll > lastScroll && currentScroll > headerHeight)
	
	lastScroll = currentScroll
}

export default observeScrollDirection