function handleNavItemHover () {
	const background = document.getElementById('menu-background')
	const menu = document.getElementById('menu')
	menu.addEventListener('mouseover', (event) => {
		const target = event.target
		if(target.nodeName === 'A') {
			background.classList.remove('background-hidden')
			const hoveredPage = event.target.getAttribute('data-page')

			if (background.hasAttributes()) {
				const currentMenuPage = background.getAttribute('data-page')

				if (currentMenuPage !== hoveredPage) {
					background.setAttribute('data-page', hoveredPage)
				}
			}
		} else {
			background.classList.add('background-hidden')
		}
	})
}

export default handleNavItemHover
